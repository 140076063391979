.barChart-x-axis path {
  stroke: #f0f0f6;
}

.barChart-x-axis .x-axis-label {
  font-size: 8px;
  color: rgba(30, 58, 83, 0.5);
  font-weight: 500;
  text-align: center;
}

.barChart-x-axis line {
  display: none;
}

.barChart-x-axis text {
  font-size: 8px;
  text-anchor: start;
  color: #555569;
}
.barChart-x-axis path {
  d: path('M0,0.5H570.5')
 }

 .heatMap-x-axis path {
  d: path('M-50,0.5H398.5')
 }
.barChart-y-axis path {
  stroke: #f0f0f6;
}


.heatMap-y-axis path, .heatMap-x-axis path {
  stroke: #f0f0f6;
}


.heatMap-y-axis text, .heatMap-x-axis text  {
  font-size: 8px;
  color: #555569;
}
.heatMap-x-axis text {
  text-anchor: start;

}

.tooltipClass {
  position: absolute;
  z-index: 10;
  visibility: hidden;
}

.legend_tooltip_arrow {
  position: relative;
  min-width: 170px;
  padding: 10px;
  text-align: center;
  width: 120px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border: 0;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  top: -5px;
  left: 110%;
}
.legend_tooltip_arrow p {
  margin: 0;
}


.legend_tooltip_arrow::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #fff;
  left: -8px;
  top: 7px;
}

.legend_tooltip_arrow:after {
  content: '';
  display: block;  
  position: absolute;
  left: -8px;
  top: 7px;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-right-color: white;
}
.barChart-y-axis g line {
  display: none;
  transform: translate(5px, 0px);
}

.barChart-y-axis text {
  font-size: 8px;
  color: #555569;
}

body {
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
}
