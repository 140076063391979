// Here you can add other styles
* {
  &::-webkit-scrollbar {
    display: none;
  }
}
input:focus
{
  outline:0px;
}
.app-body {
  &::-webkit-scrollbar {
    display: none;
  }
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.loader {
  .modal-content {
    background-color: transparent;
    border: none;
  }
  .lds-ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.author-align {
  align-items: center;
  button {
    max-height: 39px;
    margin-top: 12px;
  }
}

.spaceBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// .CardProvider {

// }
.inner-table {
  th {
    background-color: #fff !important;
  }
}
.columnAlign {
  display: flex;
  flex-direction: column;
}
.marginTopAuto {
  margin-top: auto;
}

.bgWhite {
  background-color: #fff;
  .card-header {
    background-color: transparent !important;
  }
  .cardText {
    color: #555569;
    font-size: 14px;
    line-height: 21px;
  }
  .cardTitle {
    color: #555569;
    font-size: 20px;
    line-height: 24px;
  }
}

// Custom Styles

.cardContainer {
  top: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
}

.widthPercent20 {
  width: 20%;
}

.widthPercent30 {
  width: 30%;
}

.widthPercent170 {
  width: 170%;
}

.widthPercent50 {
  width: 50%;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop10 {
  margin-top: 10px;
}
.marginTop40 {
  margin-top: 40px;
}

.bgTransparent {
  background-color: transparent;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  @media (max-width: 495px) {
    flex-direction: column;
    .secondItem {
      width: 160px;
      margin-top: 20px;
    }
    .heading {
      font-size: 20px;
    }
  }
}
.form-control {
  &:focus {
    box-shadow: none !important;
  }
}
.onboardProvider {
  .formFlex {
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: -15px;
      .fontStyle {
        margin-left: 15px;
      }
    }
  }
}

.spaceAround {
  display: flex;
  justify-content: space-around;
}

.spaceAround1 {
  justify-content: space-around;
  select {
    width: 50% !important;
    flex: none !important;
  }
  & > div {
    width: 50%;
    & > span {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
  }
}

.spaceAroundFlexEnd {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.justifyFlexEnd {
  display: flex;
  justify-content: flex-end;
}

.spaceBetweenAlignCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.justifyCenter {
  display: flex;
  justify-content: center;
}

.font25 {
  font-size: 25px;
}
.font20 {
  font-size: 20px;
}

.font15 {
  font-size: 15px !important;
  line-height: 30px;
}

.cardProvider {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 263px;
  border-radius: 8px;
  background-color: #f0f0f6;
}

.paddingLeft40 {
  padding-left: 40px;
}

.marginLeft-20 {
  margin-left: -20px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight30 {
  margin-right: 30px;
}

.fontStyle {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  line-height: 30px;
}

.fontStyle1 {
  font-size: 15px !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
  line-height: 30px !important;
}

.formInputName {
  font-weight: 400;
}

.fontText {
  font-size: 18px;
}

.noItems {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerStyles {
  color: #555569;
  font-size: 27px;
  line-height: 30px;
  border-bottom: 0px !important;
}

.textStyle {
  color: #555569;
  font-size: 18px;
  line-height: 22px;
}

.subTextStyles {
  color: #555569;
  font-size: 14px;
  line-height: 22px;
}

.buttonStyles {
  height: 46px;
  background-color: #555569;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
}

.form-control-alternative {
  border: 1px solid #d6d6de !important;
  border-radius: 5px;
  background-color: #ffffff;
}

.rightContainerOffer {
  // height: 206px;
  // width: 231px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px;
  border: 1px solid #f0f0f6;
  border-radius: 10px;
  background-color: #ffffff;
}

.filterHeadStyle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.filterStyle {
  border: 1px solid #e0e0e2;
  border-radius: 16.5px;
  background-color: #ffffff;
  padding: 5px 10px;
  margin-left: 10px;
  &.active {
    background-color: #613a95;
    color: #fff;
  }
}

.table th {
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.tableHeadStyle {
  height: 56px;
  border-radius: 5px;
  background-color: #f0f0f6;
}

.searchBar {
  position: relative;
  width: 23%;
}
.searchBar .iconify {
  position: absolute;
  right: 15px;
  top: 8px;
  font-size: 18px;
}

.sortBy {
  width: 153px;
  position: relative;
  height: 40px;
  border: 1px solid #d6d6de;
  border-radius: 5px;
  background-color: #ffffff;

  /* margin-left: 10px; */
}

.sortBy__header {
  height: 40px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  span {
    color: #555569;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
  .iconify {
    margin-top: 5px;
  }
  i {
    font-size: 15px;
  }
}

.sortBy__dropdown {
  //  display: none;
  position: absolute;
  padding-left: 0px;
  right: 0px;
  left: 0px;
  // max-height: 0px;
  // height:0px;
  // overflow: hidden;
  top: 38px;
  // left: -1px;
  // right: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #ededed;

  background-color: #ffffff;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  // z-index: 11;
  // transition: max-height 0.5s ease-in;
}

.sortBy__dropdown.active {
  display: block;
  // height:auto;
  max-height: 200px;
  border: 1px solid #ededed;
  transition: max-height 0.5s ease-out;
  overflow-y: scroll;
}

.sortBy__dropdown li {
  color: #555569;
  padding-left: 10px;
  font-size: 13px;
  letter-spacing: -0.2px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  list-style-type: none;
}
.sortBy__dropdown li:hover {
  color: #000;
}

.circle {
  background-color: #e0e2ed;
  height: 74px;
  width: 74px;
  border-radius: 50%;
  margin-top: 25px;
}

.disabled {
  opacity: 0.5;
}

.parentsList {
  .card-title {
    margin-top: 20px;
    margin-bottom: 0px !important;
    line-height: 22px;
  }
}

.modalStyles {
  padding: 5% 0px;
  /* background-color: transparent; */
  max-width: 800px;
  width: 100%;
  margin: 30px auto;
}

// provider edit

.main-content {
  position: relative;

  // Navbar
  .navbar-top {
    position: absolute;
    left: 0;
    top: -19px;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.main-content .container-fluid img {
  transform: scale(0.9);
}

.shadow {
  box-shadow: 0 0 1rem 0 rgba(136, 152, 170, 0.15) !important;
}

// Landing Page Styles

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700,800,900&display=swap");

body {
  font-family: "Quicksand", "Roboto", sans-serif;
}
.slick-dots {
  bottom: 20px !important;
  li {
    button {
      &:before {
        font-size: 15px !important;
        color: #e0e2ed !important;
      }
    }
    &.slick-active {
      button {
        &:before {
          color: #555569 !important;
        }
      }
    }
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.paddingLeft {
  padding-left: 30px;
  color: #643b99;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.sliderCard {
  height: 717px;
  background-color: #f0f0f6;
  color: #555569;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sliderCardTitle {
    font-size: 34px;
    line-height: 50px;
  }
  .sliderCardSubTitle {
    font-size: 16px;
    line-height: 19px;
  }
  .sliderCardButton {
    top: 20px;
    height: 50px;
    width: 269px;
    border-radius: 74px;
    background-color: #ffffff;
    box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  }
  .sliderCardButton span {
    font-size: 16px;
    line-height: 19px;
  }
}

.partOfKiddenz {
  color: #555569;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-top: 1.25rem;
}

.textCenter {
  text-align: center;
}

.emptyCard {
  height: 249px;
  width: 100%;
  background-color: #f0f0f6;
}

.emptyCardContainer {
  border: 0 !important;
}

.sliderWrapper2 {
  margin-top: 30px;
  .slick-dots {
    bottom: 80px !important;
    left: -238px !important;
  }
  .sliderCard2 {
    background-color: #f0f0f6;
    color: #555569;
    padding-top: 63px;
    padding-left: 156px;
    padding-bottom: 60px;
    .sliderCardHeader {
      font-size: 24px;
      line-height: 28px;
    }
    .circle {
      height: 191px;
      width: 191px;
      background-color: #e0e2ed;
      border-radius: 50%;
    }
    .justifyCardData {
      display: flex;
      .sliderText {
        width: 687px;
        margin-left: 30px;
      }
    }
  }
}

.infoContent {
  color: #555569;
  margin-top: 40px;
  padding-left: 50px;
  display: flex;
  justify-content: space-around;
  .cardContent {
    border: 0px !important;
    .cardTitle {
      font-size: 22px;
      line-height: 27px;
    }
    .cardText {
      font-size: 16px;
      line-height: 24px;
    }
    .cardButton {
      border-radius: 5px;
      background-color: #555569;
      width: 50%;
      span {
        color: #ffffff;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &.bg-color {
      background-color: #f0f0f6;
      .cardBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .cardTitle {
          font-size: 24px;
          line-height: 28px;
        }
        .cardText1 {
          font-size: 16px;
          line-height: 18px;
        }
        .cardText2 {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}
.weekDays {
  color: #643b99;
  font-size: 15px;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
  line-height: 19px;
  text-transform: uppercase;
}
.cardFooter {
  background-color: #e2e2f0;
  .footerList {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .footerItems {
      color: #555569;
      font-size: 14px;
      line-height: 21px;
      .nav-link {
        &:first-child {
          font-weight: 600;
          padding-bottom: 15px;
        }
      }
    }
  }
  .footerLink {
    .footerItem {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #555569;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

// Form Styles

.formClass {
  color: #555569;
  background-color: #f8f9fe;
  padding: 0px 30px;
  border: 0px;

  .cardTitle {
    font-size: 34px;
    line-height: 30px;
  }
  .cardText {
    font-size: 16px;
    line-height: 22px;
  }
  &-container {
    width: 100%;
    max-width: 1290px;
    margin: 0px auto;
    background-color: #fff;
    box-shadow: 0 0 1rem 0 rgba(136, 152, 170, 0.15) !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    margin: 30px auto;
  }
  .subInfo {
    font-weight: 300;
    font-size: 12px;
  }
}
.radioBtn {
  margin: 20px 0px 30px !important;
  .col {
    padding: 0px;
  }
}
.formFlex {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 0 0 30px;
  .marginTop20 {
    padding-left: 30px;
  }
  .btn-primary {
    background-color: #fff !important;
    border-color: #60b947 !important;
    color: #60b947 !important;
  }
  .btn-provider {
    background-color: #fff !important;
    border-color: #60b947 !important;
    color: #60b947 !important;
  }
  .password {
    position: absolute;
    color: #555569;
    right: 20px;
    top: 16px;
  }
  button {
    border-radius: 5px;
    background-color: #60b947;
    color: #fff;
    font-weight: 400;
    &:hover {
      color: #60b947;
      background-color: #fff;
      border-color: #60b947;
    }
    &.disabled {
      background-color: #cccccc;
      color: #666666;
    }
  }
  &.column {
    flex-direction: column;
    align-items: flex-start;
    .col {
      min-height: auto;
    }
  }
}

// Login styles
.loginPage {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  margin: 0px !important;
  padding: 60px;
  // background-image: url("../img/provider login illustration.png");
  // background-position: bottom left;
  // background-repeat: no-repeat;
  // background-size:;
}
.nonLoginContainer {
  padding: 0px !important;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 65%;
  // background-color: #f0f0f6;

  img {
    width: 100%;
    height: 100%;
  }
}
.marginRight-24 {
  margin-right: -24px;
}
.loginContainer {
  background-color: transparent;
  width: 500px;
  .cardTitle {
    font-size: 25px;
    line-height: 30px;
    color: #30333b;
    font-size: 20px;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
  }
  .cardText {
    color: #555569;
    font-size: 14px;
    line-height: 17px;
  }
}

.dashboardContainer {
  .cardTitle {
    font-size: 25px;
    line-height: 30px;
    color: #30333b;
    font-size: 20px;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
  }
  .cardText {
    color: #555569;
    font-size: 14px;
    line-height: 17px;
  }
}

.loginCard {
  width: 100%;
  padding-bottom: 40px;
  border: 0px;
  border-radius: 10px;
  background-color: #f8f4fe;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  .card-header {
    background-color: transparent;
    padding: 50px 50px 0rem;
    border-bottom: 0px;
  }
  .cardBody {
    padding: 1.5rem 50px;
  }
  .form-control-alternative {
    background-color: #ffffff;
    &:focus {
      border: 1px solid rgb(139, 140, 141);
    }
  }
  .btn-primary {
    border-radius: 5px;
    background-color: #60b947 !important;
    border-color: #60b947 !important;
    color: #fff;
    height: 56px;
    font-weight: 400;

    &:hover,
    &:not(:disabled):not(.disabled):active {
      color: #60b947;
      background-color: #fff;
      border-color: #60b947;
    }
  }
}

.cardBody {
  .row {
    margin: 0px;
  }
  .col-12,
  .col {
    padding: 0px;
  }
}
.form-check {
  padding-left: 0px !important;
}
// Provider profile styles

.basicContainer {
  width: 100%;
  margin: 20px auto;
  padding: 0px !important;
  .headerStyle {
    background-color: #fff;
  }
  .card {
    border: 0px !important;
    background-color: transparent;
  }
  .cardHeader {
    background-color: transparent;
    .cardTitle {
      color: #555569;
      font-size: 22px;
      line-height: 27px;
      font-family: "Quicksand", sans-serif;
      margin-bottom: 10px;
      font-weight: 600;
    }
    .cardText {
      color: #555569;
      font-size: 14px;
      line-height: 21px;
      &.disabled {
        opacity: 0.4;
      }
    }
    .cardProfileText {
      font-size: 16px;
      line-height: 24px;
      padding-right: 50px;
    }
  }
  .progressWrapper {
    .cardText {
      display: flex;
      justify-content: space-between;
      padding: 0 15px;

      span {
        // margin-right: 45px;
        margin-top: 10px;
        cursor: pointer;
        color: #555569 !important;
        font-size: 16px;
        font-family: "Quicksand", sans-serif;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        padding: 0 0 20px;
        @media (max-width: 1350px) {
          margin-right: 30px;
        }
        @media (max-width: 1250px) {
          margin-right: 4px;
          font-size: 14px;
        }

        &.active {
          color: #60b947 !important;
          position: relative;

          &::after {
            position: absolute;
            content: "";
            bottom: -1px;
            left: 0;
            height: 2px;
            width: 100%;
            border-radius: 5px;
            background-color: #60b947;
          }
        }

        img {
          height: 15px;
          margin-right: 2px;
        }
      }
    }
    .progress {
      width: 100%;
    }
    .progressText {
      display: flex;
    }
  }

  .formContent {
    .formCheckbox {
      .col-2 {
        margin-left: -70px !important;
      }
      .row {
        & > div:nth-child(even) {
          margin-left: -70px !important;
        }
      }
      .marginLeft {
        margin-left: 15px;
      }
      .customCheckBox {
        padding-left: 0px !important;
      }
    }
    .livestream {
      font-size: 14px;
    }
    .extended {
      .col-2 {
        margin-left: -70px !important;
      }
      .dt {
        margin-left: 20px !important;
      }
      .marginLeft {
        margin-left: -70px !important;
      }
      .uploadMargin {
        margin-left: -90px !important;
      }
    }
    .weekendCare {
      .col-2 {
        margin-left: -70px !important;
      }
      .dayTime {
        .row {
          & > div:nth-child(even) {
            margin-left: -100px !important;
          }
        }
      }
    }
    .childrenAge {
      .row {
        & > div:nth-child(even) {
          margin-left: -40px !important;
          @media (max-width: 1250px) {
            margin-left: -20px !important;
          }
        }
      }
    }
    .secondChild {
      margin-left: -140px !important;
    }
    .daySchedule {
      .dayTime {
        span {
          align-self: flex-end;
          cursor: pointer;
        }
      }
    }
    .dayTime {
      display: flex;
      // margin-left: -140px;
      max-height: 46px;
      margin-bottom: 30px;
      // @media (max-width:1250px)
      // {
      //   justify-content: space-between;
      // }
      & > div:nth-child(2) {
        margin-left: -45px !important;
        @media (max-width: 1250px) {
          margin-left: -20px !important;
          padding: 0px 10px;
        }
      }
      & > div:nth-child(3) {
        margin-left: -45px !important;
        @media (max-width: 1250px) {
          margin-left: -20px !important;
          padding: 0px 10px;
        }
      }
    }
    .scheduleRow {
      margin-bottom: 20px;
      .schedule {
        display: flex;
        align-items: center;
        & > div {
          font-size: 0.875rem;
          display: block;
          margin: 0rem 20px 0rem 0px;
          padding: 0.75rem;
          color: #393f49;
          border-radius: 0.25rem;
          background-color: #fff;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
            0 4px 16px rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }
    }
  }
}
.cursor {
  cursor: pointer;
}
.information {
  cursor: pointer;
  position: relative;
  svg {
    height: 18px;
    width: 18px;
    margin-left: 3px;
    margin-bottom: 3px;
    border-radius: 50%;
    background-color: #666c78;
    path {
      fill: #ffffff;
    }
  }

  &:hover {
    .tooltip-hover {
      display: block;
      opacity: 1;
    }

    svg {
      background-color: #60b947;

      path {
        fill: #ffffff;
      }
    }
  }
}
.upload-file-custom {
  color: #60b947;
}

.information1 {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
  text-decoration-color: #60b947;
  font-size: 0.875rem;
}

.tooltip-hover {
  position: absolute;
  bottom: 24px;
  left: -10px;
  min-width: 200px;
  background-color: #ffffff;
  padding: 8px 15px;
  border-radius: 8px;
  display: none;
  opacity: 0;
  z-index: 6;
  -webkit-box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.09);

  // &::before {
  //   position: absolute;
  //   content: "";
  //   bottom: -20px;
  //   left: 6px;
  //   z-index: 1;
  //   border-left: 10px solid transparent;
  //   border-top: 10px solid #ffffff;
  //   border-right: 10px solid transparent;
  //   border-bottom: 10px solid transparent;
  // }
  // &::after {
  //   position: absolute;
  //   content: "";
  //   bottom: -22px;
  //   left: 6px;
  //   border-left: 10px solid transparent;
  //   border-top: 10px solid #f2f2f2;
  //   border-right: 10px solid transparent;
  //   border-bottom: 10px solid transparent;
  // }

  span {
    color: #555569;
    font-size: 16px;
    line-height: 21px;
  }
}
.plusIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #c0c8cd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
  .iconify {
    color: #fff;
  }
  &:hover {
    background-color: #60b947;
  }
}

.plusIcon1 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  .iconify {
    color: #60b947;
  }
  &:hover {
    background-color: #fff;
  }
}
.addActivity {
  background-color: #60b947;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  border: none;
  box-shadow: none;
  height: 46px;
  &:hover {
    color: #60b947;
  }
}

.sublabel {
  color: #555569;
  font-size: 14px;
  line-height: 17px;
  padding-right: 30px;
}

.addMore {
  color: #555569;
  margin: 0 0 10px;
  font-weight: 400;

  font-size: 14px;
  line-height: 19px;
}

.alignItems {
  display: flex;
  justify-content: space-between;
  .nav-tabs {
    border-bottom: 0 !important;
    align-items: center;
  }
  // & > ul:first-child {
  //   margin-right: 50px;
  // }
  color: #555569 !important;
  font-size: 18px;
  line-height: 22px;
  .nav-item {
    .nav-link {
      position: relative;
      border-width: 0px;
      font-size: 16px;
      font-weight: 400;
      padding: 0.75rem 0.75rem;
      color: #fff !important;
      cursor: pointer;
      &.active {
        border: 0 !important;
        background-color: transparent !important;
      }
    }
    .active {
      color: #09090a !important;
      &::after {
        position: absolute;
        content: " ";
        width: 100%;
        height: 2px;
        background-color: #09090a;
        bottom: 0px;
        left: 0px;
      }
    }
  }
}

.headerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .smallCircle {
    height: 64px;
    width: 64px;
    background-color: #f0f0f6;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rightContentStyle {
    & > span {
      margin-right: 20px;
    }
    div {
      margin-bottom: 0;
    }
  }
}

.radioType {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -20px;
    top: 6px;
    width: 16px;
    height: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: red;
    -webkit-border-image: none;
    border-image: none;
    -webkit-border-image: initial;
    border-image: initial;
    border-radius: 100%;
  }
}
input[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  color: red;
  // font-family: Roboto, sans-serif;
  font-size: 16px;
  // font-weight: 700;
  line-height: 16px;
  display: inline-block;
  white-space: nowrap;
}

.customCheckBox {
  margin: 0;
  display: block;
  align-items: center;
  padding-left: 0px;
  label {
    position: relative;
    margin-bottom: 16px;
    padding-left: 35px;
    span {
      // margin-left: 5px;
      color: #555569;
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
    }
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    left: 44px;
    top: 0px;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
  }

  label:before {
    content: "";
    -webkit-appearance: none;
    // background-color: #3B74E7;
    background-color: #fff;
    border: 1px solid #c0c8cd;
    // border: 1px solid #3B74E7;
    border-radius: 5px;
    margin-top: 2px;
    padding: 5px;
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    left: 0;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12;
  }
  input:checked + label:before {
    // background:  #D6D6DE;
    border-width: 2px solid #d6d6de;
  }
  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
  input:checked + label:before {
    background-color: #60b947;
  }
  input:disabled {
  }

  input:disabled + label:after {
    background-color: #60b947;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    cursor: default;
  }
  input:disabled + label:before {
    border-color: #fff;
    cursor: default;
  }
  input:disabled + label {
    color: #fff;
    cursor: default;
  }
}

.customRadio {
  position: relative;
  z-index: 0;
  margin-bottom: 10px;
  input[type="radio"]:checked {
    position: absolute;
    top: 8px;
    z-index: 10;
    left: 5px;
    opacity: 0;
  }
  input[type="radio"]:not(:checked) {
    position: absolute;
    top: 8px;
    z-index: 10;
    left: 5px;
    opacity: 0;
  }
  input[type="radio"]:checked + label {
    position: relative;
    padding-left: 35px;
    padding-top: 3px;

    cursor: pointer;
    color: #555569;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    white-space: nowrap;
  }
  input[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 35px;
    padding-top: 3px;
    cursor: pointer;
    color: #555569;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    white-space: nowrap;
  }
  input[type="radio"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #60b947;
    border-radius: 100%;
  }
  input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #60b947;
    border-radius: 100%;
    opacity: 0.5;
  }
  input[type="radio"]:checked + label:after {
    content: "";
    width: 14px;
    height: 14px;
    background: #60b947;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  input[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 14px;
    height: 14px;
    background: #60b947;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  input[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// Common styles

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.marginTop20 {
  margin-top: 20px;
}

.margin0 {
  margin: 0px;
}

.width100 {
  width: 100%;
}

.backgroundWhite {
  background-color: #fff;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.paddingLeft30 {
  padding-left: 30px;
}

.marginRight-60 {
  margin-right: -60px;
}

.marginLeft-70 {
  margin-left: -90px;
}
.form-control-alternative {
  // border: 1px solid transparent;

  &:-moz-focusring {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
  }
}
.activeSchedule {
  background-color: #60b947 !important;
  border: 1px solid #60b947 !important;
  color: #fff !important;
  position: relative;
  .closeIcon {
    position: absolute;
    color: #fff !important;
    width: 16px !important;
    height: 16px !important;
    top: 1px;
    right: -1px;
    &:hover {
      color: #000 !important;
    }
  }
  span {
    color: #fff !important;
  }
}
#mainContent {
  width: calc(1440px - 250px);
  margin-left: auto;
}
.scheduleBg {
  background-color: #fff;
  padding: 10px;
  border-radius: 10%;
}

.flexSpaceBtn {
  display: flex;
  justify-content: space-around;
}

.uploadImage {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    margin-top: 10px;
  }
}

.trash {
  height: 18px;
}
.addEvent {
  color: #60b947;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  margin-bottom: 12px;
  border-radius: 8px;
}

.teacherSubtitle {
  padding: 4px;
  border: 1px solid #ebe9e9;
  font-size: 14px;
  color: #555569;
  background-color: #faf5ff;
}
.locationImage {
  width: 100%;
  height: 500px;
  border: 5.5px solid #fff;
  img {
    width: 100%;
    height: 100%;
  }
}
.form-control::-webkit-input-placeholder {
  font-size: 0.875rem;
  color: #8898aa;
  font-weight: 300;
}
.form-control {
  font-size: 0.875rem;
  height: calc(2.75rem + 2px);
  color: #393f49;
  resize: none;
}
.form-control.is-invalid {
  background-image: none !important;
}
.form-control::-webkit-scrollbar {
  visibility: hidden;
}
.formFlex.justifyStart {
  justify-content: flex-start;
}
.btn-secondary {
  border-color: #f7fafc;
}
// .btn-secondary:hover {
//   border-color: #212529 !important;
// }
.btn-secondary.disabled:hover {
  border-color: #f7fafc !important;
  box-shadow: none;
  transform: translateY(0px);
}

.btn {
  &:focus {
    box-shadow: none !important;
  }
}
.extendedHour {
  justify-content: space-between;
}
.extendedHour .col,
.extendedHour .col-6 {
  padding-left: 0px !important;
}
.inputMarginLeft {
  margin-left: -44px;
}
.timePicker {
  width: 100%;

  .rc-time-picker-input {
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    border: 0px solid #d9d9d9;
    box-shadow: 0 1px 10px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    font-size: 0.875rem;
    color: #31383f;
    font-weight: 300;

    &::-webkit-input-placeholder {
      font-size: 0.875rem;
      color: #8898aa;
      font-weight: 300;
    }
  }
}
.rc-time-picker-panel {
  width: 192px !important;
  padding-top: 3px;
}
.rc-time-picker-panel-inner {
  width: 100%;
}
.rc-time-picker-panel-input-wrap {
  display: flex;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem !important;
  border: 0px solid #d9d9d9;
}
.rc-time-picker-panel-input::-webkit-input-placeholder {
  font-size: 0.875rem;
  color: #8898aa;
  font-weight: 300;
}
.rc-time-picker-clear {
  right: 7px !important;
  top: 10px !important;
}
.searchBar {
  position: relative;
  width: 23%;
}
.searchBar .iconify {
  position: absolute;
  right: 15px;
  top: 15px;
}
.customRadio input[type="radio"][disabled]:checked + label:before {
  border: 1px solid #939494;
}
.customRadio input[type="radio"][disabled]:not(:checked) + label:before {
  border: 1px solid #939494;
}

.customRadio input[type="radio"][disabled]:checked + label:after {
  background-color: #939494;
}

.workingDays {
  display: flex;
  margin-bottom: 10px;
}
.workingDays li {
  width: 20%;
  list-style-type: none;
  &:first-child {
    width: 29%;
  }

  &:first-child {
    margin-left: 15px;
  }
}
.selectDate {
  display: flex;
}
.selectDate li {
  width: 26%;
  list-style-type: none;
  margin-bottom: 10px;

  &:first-child {
    margin-left: 46px;
  }
}
.scheduleText {
  font-size: 80%;
  color: #fb6340;
}
.monthlyFee {
  display: flex;
}
.monthlyFee li {
  width: 40%;
  list-style-type: none;
  margin-bottom: 10px;
  font-size: 14px;
  &:first-child {
    margin-left: 10px;
  }
}
.time-Picker {
  display: flex;
  width: 60%;
  .col-12,
  .form-group,
  .col {
    padding-left: 0px !important;
  }
}
.time-Picker li {
  width: 35%;
  list-style-type: none;
  margin-bottom: 10px;
  &:first-child {
    margin-left: 10px;
  }
}
.react-datepicker-wrapper {
  padding-right: 15px;
  width: 100%;
}

.navbar-brand-full {
  transform: scale(1.4);
}
.react-datepicker__input-container {
  // box-shadow: 0 1px 10px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  // border: 0;
  height: calc(2.75rem + 2px);
  border: 1px solid #d6d6de;
  border-radius: 5px;
  background-color: #ffffff;
}
.zoomInOut {
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 2;
  border-radius: 4px;
  > i {
    cursor: pointer;
    padding: 6px;
    border-bottom: 1px solid grey;
  }
}

.cropContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: -webkit-fill-available;
}

.disabledDatePicker {
  background-color: hsl(0, 0%, 95%);
}
.react-datepicker__input-container input {
  height: 44px;
  padding-left: 10px;
  border-radius: 6px;

  width: 100%;
  border: 0;
  font-size: 0.875rem;
  color: #494c4e;
  font-weight: 300;
}
.react-datepicker__input-container input::-webkit-input-placeholder {
  font-size: 0.875rem;
  color: #8898aa;
  font-weight: 300;
}
.react-tagsinput {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0px !important;
  padding: 10px 0.75rem !important;
  background-color: #fff;
  border-radius: 0.375rem;
  margin-left: 15px;
}
.react-tagsinput-input::-webkit-input-placeholder {
  font-size: 0.875rem !important;
  color: #8898aa !important;
  font-weight: 300 !important;
}
.react-tagsinput-input {
  margin-bottom: 0px !important;
}
.react-tagsinput-tag {
  font-size: 0.8rem !important;
  color: #393f49 !important;
  background-color: #e2e8f5 !important;
  border: 1px solid #393f49 !important;
}
.facilityRadio {
  margin-left: 0px;
  width: 40%;
}

.uploadMore {
  height: 135px;
  display: flex;
  margin: 1rem 0px;
  padding: 1rem;
  color: rgb(57, 63, 73);
  border-radius: 0.25rem;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 16px;
  place-content: center;
  align-items: center;
  width: 200px;
}

.buttonSchool {
  display: flex;
  justify-content: center;
  align-items: center;
}

.commonContent {
  margin-top: 30px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  border: 0px solid #393f49;
  background-color: #f0f0f6;
  border-radius: 12px;
  padding: 10px;
  font-size: 16px;
  line-height: 26px;
}

.photoSpecification {
  display: flex;
  justify-content: space-between;
  .specificationsInner {
    margin-top: 30px;
    padding: 20px;
    width: 60%;
    border: 1px solid #393f49;
    border-radius: 12px;
    div {
      color: #555569;
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;
    }
  }
  .photos {
    background-color: #f0f0f6;
    padding: 20px;
    border: 0px solid #393f49;
    border-radius: 12px;
    width: 30%;
  }

  // border: 0px solid #393f49;
  // border-radius: 12px;
  // width: 70%
}

.modalBody {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 0px;
  .modalBody-margibBtm {
    margin-bottom: 20px;
  }
  // line-height: 30px
}

.modalFooter {
  margin-top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid-feedback {
  display: none;
  width: 100%;
  font-weight: 300 !important;
  margin-top: 0.25rem;
  font-size: 11px !important;
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bg-gradient-info {
  min-height: 80px;
}

.searchInput {
  display: flex;
  padding-left: 15px;
  margin-bottom: 20px;
  &-bar {
    width: 46%;
    position: relative;
    padding: 0px 10px;
    .iconify {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 34px;
      height: 22px;
      background-color: white;
      color: #84909e;
    }
    .textarea {
      font-family: Open Sans, sans-serif !important;
      font-size: 0.875rem;
      color: #393f49;
      background: #fff;
      width: 100%;
      border: 0px;
      height: calc(2.75rem + 2px);
      padding: 0.625rem 2rem 0.625rem 0.75rem;
      border-radius: 0.375rem;
      box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
      // height: 82px;
      &::placeholder {
        font-family: Open Sans, sans-serif !important;
        font-size: 0.875rem;
        color: #8898aa;
        font-weight: 300;
      }
      &::-webkit-scrollbar {
        display: none;
        width: 0px; /* Remove scrollbar space */
        background: transparent;
      }
    }
  }
  &-dropdown {
    position: absolute;
    max-height: 150px;
    overflow: scroll;
    cursor: default;
    top: 50px;
    padding-left: 0px;
    background-color: #fff;
    width: 91%;
    z-index: 9;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);

    > :last-child {
      span {
        border: none;
      }
    }

    span {
      font-size: 0.875rem;
      color: #393f49;
      padding: 10px 15px;
      border-bottom: 1px solid #dcdcdc;
      display: flex;
      align-self: center;
    }
  }
  h2 {
    width: 51%;
  }
  input {
    display: block;
    width: 100%;
    border: 0px;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    outline: none;
    background-color: transparent;
    &::-webkit-input-placeholder {
      font-size: 0.875rem;
      color: #8898aa;
      font-weight: 300;
    }

    &::selection {
      outline: none;
    }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }
}
.alert-message {
  border-radius: 6px 6px 0 0;
  background-color: #faf5ff;
  padding: 16px 32px 0 32px;
  position: relative;
  overflow: hidden;

  p {
    color: #555569;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    margin: 0;
    a {
      color: #60b947;
      padding: 0 4px;
    }
  }

  .picture {
    position: absolute;
    right: 0;
    top: -7px;
    height: 214px;
  }
}

.borderDashed {
  border-bottom: 1.6px dashed rgba(102, 108, 120, 0.2);
}

.subHeading {
  color: #643b99;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 15px;
}

.subText {
  color: #666c78;
  font-size: 14px;
  line-height: 24px;
  max-width: 256px;
}

.setWidth {
  li {
    width: 26%;
  }
}

.subTitle {
  color: #643b99;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.flex {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.forgotPassword {
  float: right;
  font-size: 12px;
  margin: 8px 0;
  font-weight: 500;
  cursor: pointer;
}

.type2-dropdown {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0px 15px;
  // z-index: 0;

  .dd-button {
    // width: 100%;
    height: calc(2.75rem + 2px);
    display: inline-block;
    padding: 0.625rem 0.75rem;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    color: #31383f;
  }
  .dd-button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  .dd-input {
    display: none;
  }
  .dd-menu {
    position: absolute;
    top: 102%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 4px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    z-index: 22;
  }
  .scroll {
    height: 120px;
    overflow-y: scroll;
  }
  .dd-input + .dd-menu {
    display: none;
  }
  .dd-input:checked + .dd-menu {
    display: block;
  }
  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  .dd-menu li:hover {
    background-color: #f6f6f6;
  }
  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  .dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
}

.profileCircle {
  height: 149px;
  width: 149px;
  background-color: #e0e2ed;
  border-radius: 50%;
}

.disabled {
  opacity: 0.5;
}

.marginTop-20 {
  margin-top: -20px;
}

.marginTop-15 {
  margin-top: -15px;
}

.marginLeft-15 {
  margin-left: -15px;
}

.scheduledTable {
  tr > th {
    color: #555569;
    font-size: 16px;
    line-height: 24px;
    // text-align: center;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0px;
  }
  tr > td {
    color: #555569;
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
  }
}

.basicContainer .formContent .dayTime {
  .form-group {
    @media (max-width: 1250px) {
      padding: 0px !important;
      margin-right: 10px;
    }
  }
  .rc-time-picker-input {
    padding: 0.625rem 0.5rem;
  }
}

.sortBy {
  width: 153px;
  position: relative;
  height: 40px;
  border: 1px solid #d6d6de;
  border-radius: 5px;
  background-color: #ffffff;

  /* margin-left: 10px; */
}

.sortBy__header {
  height: 40px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  span {
    color: #555569;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
  .iconify {
    margin-top: 5px;
  }
  i {
    font-size: 15px;
  }
}

.sortBy__dropdown {
  //  display: none;
  position: absolute;
  padding-left: 0px;
  right: 0px;
  left: 0px;
  // max-height: 0px;
  // height:0px;
  // overflow: hidden;
  top: 38px;
  // left: -1px;
  // right: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #ededed;

  background-color: #ffffff;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
  // z-index: 11;
  // transition: max-height 0.5s ease-in;
}

.sortBy__dropdown.active {
  display: block;
  // height:auto;
  max-height: 200px;
  border: 1px solid #ededed;
  transition: max-height 0.5s ease-out;
  overflow-y: scroll;
}

.sortBy__dropdown li {
  color: #555569;
  padding-left: 10px;
  font-size: 13px;
  letter-spacing: -0.2px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  list-style-type: none;
}
.sortBy__dropdown li:hover {
  color: #000;
}

.spaceBetweenAlignCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardHeader {
  border-radius: 8px;
  background-color: #f0f0f6;
  padding: 20px;
}
.dashboardBody {
  height: 59px;
  border-radius: 8px;
  background-color: #f0f0f6;
  padding: 20px;
  .cardText {
    .circle {
      height: 15px;
      width: 15px;
      background-color: #555569;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.spaceAround {
  display: flex;
  justify-content: space-around;
}

.cardProvider {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 180px;
  border-radius: 8px;
  background-color: #f0f0f6;
  margin: 10px 0px;
  padding: 10px;
  h1 {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .cardText {
    margin-bottom: 0px;
  }
}

.padding20 {
  padding: 20px;
}
.margin20 {
  margin: 20px;
}

.spaceBtnFlexEnd {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.navbar-vertical.navbar-expand-md {
  @media (max-width: 1250px) {
    max-width: 220px;
  }
}
.navbar-vertical.navbar-expand-md.fixed-left + .main-content {
  @media (max-width: 1250px) {
    margin-left: 220px;
  }
}
.navbar-vertical .navbar-nav .nav-link {
  font-family: "Quicksand", sans-serif;
}
.navbar-vertical .navbar-nav .nav-link.active {
  font-weight: 500;
}
.main-content .container-fluid img {
  transform: scale(0.9);
}
// textarea.form-control-alternative.form-control::-webkit-input-placeholder
// {
// font-size:12px;
// }

.reviewRectangle {
  background-color: #9f9f9f;
  width: 200px;
  padding: 20px;
  float: right;
  li {
    list-style-type: none;
  }
}

.customRadio .form-check-label span {
  font-size: 16px;
}

.alignCenter {
  display: flex;
  align-items: center;
}
.schedule .iconify {
  width: 26px;
  height: 26px;
  color: #60b947;
  cursor: pointer;
}
// .modal-header {
//   border-bottom-width: 1px;
// }
.btn-outline {
  border: 1px solid #484853 !important;
  color: #484853 !important;
  background: #fff;
  font-weight: 400;
}

.progressComplete {
  color: #613a95 !important;
  font-weight: 600 !important;
  position: relative;
  &::after {
    content: "";
    display: none;
    position: absolute;
    height: 2px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #60b947;
  }
  &:hover,
  &.active {
    &::after {
      display: block;
    }
  }

  img {
    height: 15px;
    margin-right: 2px;
  }
}
.contentWriting {
  display: flex;
}
.contentWriting > div {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  width: 88px;
  border: 1px solid #555569;
  border-radius: 5px;
  background-color: transparent;

  &.active {
    border: 1px solid #60b947;
    background-color: #60b947;

    span {
      color: #ffffff;
    }
  }

  span {
    color: #555569;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    line-height: 17px;
  }
}
.forgotLoginPassword {
  margin-bottom: 0px;
}

.border-datepicker {
  border: 1px solid red;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.loader {
  .modal-content {
    background-color: transparent;
    border: none;
  }
  .lds-ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.languageChips {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px !important;
  height: 40px !important;
  font-size: 16px;
  line-height: 50px;
  box-shadow: none !important;
  width: 170px;
  border: 1px solid #d6d6de;
  border-radius: 5px !important;
  background-color: #ffffff !important;
  margin-bottom: 10px !important;
  svg {
    width: 24px;
    height: 24px;
    fill: #c3c7cb;
    cursor: pointer;
  }
  &:hover {
    border: 1px solid #60b947;
    svg {
      fill: #60b947;
    }
  }
}
.redCloseIcon {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #fff;
  right: -4px;
  top: 2px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.1);

  cursor: pointer;
  &.pdf {
    width: 20px;
    height: 20px;
    left: 32px !important;
    i {
      font-size: 11px;
    }
  }
  &:hover {
    background-color: red;
    i {
      color: #fff;
    }
  }
  i {
    color: red;
    font-size: 14px;
  }
}

.trashHover {
  height: 18px;
  display: none;
}
.trashIcon:hover {
  .trashHover {
    height: 18px;
    display: block;
  }
  .trash {
    display: none;
  }
}
.phoneNumber {
  display: flex;
  align-items: center;
  height: calc(2.75rem + 2px);
  border: 1px solid #d6d6de;
  border-radius: 5px;
  background-color: #ffffff;
  position: relative;
  padding-left: 0px;
  input {
    height: 100%;
    padding-left: 40px !important;
    border: 0px solid !important;
  }
  & > span {
    position: absolute;
    font-size: 0.875rem;
    color: #393f49;
    font-weight: 400;
    left: 8px;
    line-height: 1;
  }
  .invalid-feedback {
    position: absolute;
    bottom: -20px;
  }
}
.accountApproved {
  color: #60b947;
  font-family: "Quicksand", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  margin: 10px 0px;
}

.progress {
  display: flex;
  height: 4px !important;
  overflow: hidden; // force rounded corners by cropping it
  font-size: $progress-font-size;
  background-color: #ceced9 !important;
  // @include border-radius($progress-border-radius);
  border-radius: 0 2.5px 2.5px 0 !important;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $progress-bar-color;
  text-align: center;
  white-space: nowrap;
  background-color: #60b947;
}
.borderDashed {
  .card-body {
    padding: 1.5rem 2.5rem !important;
  }
}

.rewardsModal {
  .modal-content {
    padding: 10px 10px 0px 10px;
  }
}

.animateDiv {
  &-header {
    background-color: #f7f8ef;
    padding: 15px 50px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #fff;
    justify-content: space-between;
    //  &:hover
    //   {
    //     background-color:#3a1d5f;
    //   }
    span {
      color: #30333b;
      font-size: 20px;
      font-weight: 500;
      font-family: "Quicksand", sans-serif;
      line-height: 19px;
    }
  }
  &-icon {
    padding: 5px;
    transform: rotate(360deg);
    transition: transform 0.6s ease-in-out;
    .iconify {
      color: #30333b;
      width: 26px;
      height: 26px;
      cursor: pointer;
      margin-top: 5px;
      &.minus {
        display: none;
      }
    }
    &.active {
      .iconify {
        &.minus {
          display: block;
        }
        &.plus {
          display: none;
        }
      }
    }
  }
  .borderDashed {
    display: none;
    &.active {
      display: block;
      border: 0px solid #fff;
    }
  }
}
.sub-heading {
  color: #613a95;
  font-size: 26px;
  line-height: 27px;
  font-family: "Quicksand", sans-serif;
  margin: 10px 0px 30px 10px;
  font-weight: 600;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.tooltip1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  transition: opacity 0.3s;
}

.tooltip1 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.overlapText {
  padding: 12px;
  background-color: #fff;
  border: 1px solid #d6d6de;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
}

.barChart-y-axis {
  g {
    &:nth-child(2) {
      display: none;
    }
  }
}

.btn-primary {
  background-color: #60b947 !important;
  border-color: #60b947 !important;
  color: #fff !important;
  padding: 0.575rem 1.75rem !important;
  &:hover {
    background-color: #fff !important;
    color: #60b947 !important;
  }
}

.searchLocation {
  h2 {
    color: #613a95;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 600;
    padding-left: 15px;
  }
  .searchBar {
    width: 100%;
    padding-left: 15px;
  }
  &-details {
    display: flex;
    align-items: flex-end;
    margin-left: -15px;
  }
}
.btn-compare {
  margin-left: auto;
}

.providerReport {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .col > h2 {
    color: #613a95;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 600;
  }
  .col > span {
    color: #555569;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}
.choosePeriod {
  display: flex;
  align-items: center;
  margin: 30px 0px;
  padding-left: 15px;
  h2 {
    font-weight: 600;
    font-size: 16px;
    margin-right: 20px;
  }
  .form-control {
    width: 15%;
    margin-right: 20px;
  }
}

.showResult {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 23px;
  padding-left: 15px;
  margin-bottom: 20px;

  span {
    font-weight: 600;
    margin-left: 5px;
  }
}

.barGraph {
  width: 100%;
  height: 300px;
  padding-left: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}

.dashboardReport {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px;
  &-item {
    width: 49%;
    display: flex;
    align-items: flex-start;
    padding: 32px 30px;
    border: 1px solid #dbdee5;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    transform: scale(1);

    &.school:hover {
      border-color: #60b947;
      transform: scale(1.02);
      transition: all 0.2s ease;
    }
    &.cancel:hover {
      border-color: #ec716c;
      transform: scale(1.02);
      transition: all 0.2s ease;
    }
    &.enquiry:hover {
      border-color: #3b74e7;
      transform: scale(1.02);
      transition: all 0.2s ease;
    }
    &.review:hover {
      border-color: #f8c53b;
      transform: scale(1.02);
      transition: all 0.2s ease;
    }
  }
  &-content {
    margin-left: 20px;
    width: -webkit-fill-available;
    h1 {
      color: #65c295;
      font-family: Quicksand;
      font-size: 40px;
      letter-spacing: 0;
      line-height: 40px;
      font-weight: 400;
    }
  }
  .reportValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Quicksand;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 10px;

    h2 {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 14px;
      margin-bottom: 0px;
      span {
        font-weight: 500;
      }
    }
  }
  &-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666c78;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    span {
      color: #613a95;
      font-family: Quicksand;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      min-width: fit-content;
      cursor: pointer;
      margin-left: 50px;
      &:hover {
        color: rgb(54, 26, 92);
      }
    }
  }
  .arrowRight {
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #60b947;
    border: 1px solid #60b947;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
    &:hover {
      background-color: #fff;
      .iconify {
        color: #60b947;
      }
    }
    .iconify {
      color: #fff;
    }
  }
}

.dashboardContainer {
  .cardTitle {
    font-size: 25px;
    line-height: 30px;
    color: #30333b;
    font-size: 20px;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
  }
  .cardText {
    color: #555569;
    font-size: 14px;
    line-height: 17px;
  }
}

.dashboardHeader {
  padding: 32px;
  width: 100%;
  border-radius: 10px;
  background-color: #faf6ff;
  position: relative;
  margin-bottom: 16px;
  &-img {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  &-login {
    color: #666c78;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 16px;
  }
  &-title {
    color: #613a95;
    font-family: Quicksand;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 8px;
    span {
      color: #666c78;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      margin-left: 15px;
      font-weight: 400;
    }
  }

  &-text {
    color: #60b947;
    font-family: Quicksand;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0px;
    width: 64%;
  }
}

.margin0 {
  margin: 0px;
}
.tab-content {
  border: 1px solid #613a95;
  border-top-color: #fff;
  margin-top: 0px;
  &:last-child {
    margin-top: -1.5px;
  }
}
.tab-pane {
  &.active {
    border: none;
  }
}

// .nav-tabs {
//   border-bottom: 1px solid #613a95;
//    .nav-link {
//      &.active {
//        border-color: #613a95;
//        border-bottom-color: #fff;
//      }
//      &:hover {
//       border-color: #613a95;
//       //  border-bottom-color: #fff;
//     }
//    }
// }
.nav-tabs {
  border-bottom: 1px solid #613a95;
}
.nav-tabs .nav-link.active {
  color: #2f353a;
  background: #fff;
  border-color: #613a95;
  border-bottom-color: #fff;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #613a95 #613a95 #fff;
}

.offlineData {
  &-tableData {
    background-color: #5555;
    border-top: 1px solid rgb(211, 211, 211);
    display: flex;
    padding: 22px 32px;
    margin-bottom: 0px;
    li {
      display: flex;
      justify-content: center;
      color: #30333b;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      list-style-type: none;
      width: 24%;
    }
  }
  .profileImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.responsive-design {
  @media (max-width: 840px) {
    .responsive-align {
      display: block;
      > div {
        max-width: 100% !important;
      }
    }
  }
  @media (max-width: 600px) {
    .responsive-buttons {
      display: block !important;
      button {
        width: 100% !important;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}

.error-styles {
  font-weight: 300 !important;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  font-size: 11px !important;
  color: red;
}

/* styles */

.heading {
  color: #613a95;
  font-size: 26px;
  line-height: 27px;
  margin-bottom: 10px;
  font-weight: 600;
}


.sidebar .nav-link 
{
  background: #fff;
  color: #666C78;
}

.sidebar .nav-link.active .nav-icon
{
  color: #60B947;
}

.sidebar .nav-link.active
{
  background: #fff;
  color: #613a95;
}

.sidebar .nav-dropdown.open .nav-link
{
  background:rgba(255,255,255,0.9);
  color: #666C78;
}

.sidebar .nav-dropdown.open .nav-link.active
{
  color: #613a95;
}

.sidebar .nav-dropdown.open .nav-link:hover .nav-icon
{
  color: #60B947;
}
.sidebar .nav-dropdown.open .nav-link:hover 
{
  color: #613a95;
}
.sidebar
{
  background: #fff;
}